<template>
  <div :id="id"></div>
</template>
<script>
// import echarts from 'echarts'

export default {
  props: ['id', 'data'],
  data () {
    return {
      charts: '',
      YDATA: []
    }
  },
  watch: {
    data: {
      immediate: true,
      handler (newvalue, lodvalue) {
        this.$nextTick(() => {
          this.data = newvalue
          this.initChart(this.id)
        })
      }
    }

  },
  mounted () {
    this.$nextTick(() => {
      this.initChart(this.id)
    })
  },
  created () { },
  methods: {
    initChart (id) {
      var indicatorData = [
        { name: '北', max: 360 },
        { name: '22.5', max: 360 },
        { name: '45', max: 360 },
        { name: '67.5', max: 360 },
        { name: '东', max: 360 },
        { name: '112.5', max: 360 },
        { name: '135', max: 360 },
        { name: '157.5', max: 360 },
        { name: '南', max: 360 },
        { name: '202.5', max: 360 },
        { name: '225', max: 360 },
        { name: '247.5', max: 360 },
        { name: '西', max: 360 },
        { name: '292.5', max: 360 },
        { name: '315', max: 360 },
        { name: '337.5', max: 360 }
      ]
      var legendData = []
      var seriesData = []
      for (var i = 0; i < this.data.length; i++) {
        legendData.push(this.data[i].name)
        seriesData.push({
          value: this.data[i].value,
          name: this.data[i].name
        })
      }
      this.charts = this.$echarts.init(document.getElementById(id))
      window.onresize = this.charts.resize
      this.charts.setOption({
        color: ['RGBA(184, 199, 206, 0.8)'],
        backgroundColor: 'rgba(255,255,255,0)',
        tooltip: {},
        radar: {
          indicator: indicatorData,
          axisLine: {
            show: true,
            lineStyle: {
              // color: '#000'//
              color: '#BABFC7'//
            }
          },
          nameGap: 5 // 图中工艺等字距离图的距离

        },
        series: [{
          name: '',
          type: 'radar',
          itemStyle: { normal: { areaStyle: { type: 'default' } } },
          data: seriesData
        }]
      })
    }
  }
}
</script>
